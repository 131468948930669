<template>
    <div class="login-container">
        <v-container fluid>
            <v-row justify="center" justify-md="end" class="form-container">
                <v-col cols="10" md="4" align-self="center" align-center >
                    <login-form />
                </v-col>
            </v-row>
        </v-container>
        
    </div>
</template>
<script>
import LoginForm from '@/components/LoginForm.vue'
export default {
    components:{
        LoginForm
    },
    methods:{
        
    }
}
</script>

<style scoped>
.login-container{
    height: 100vh;
    background-image: url('../assets/king-lion.jpg');
    background-position: center;
    background-size: cover;
}
.form-container{
    height: 100vh;
    background-image: radial-gradient(transparent,transparent,black);
}
</style>