<template>
  <div class="login-form-container">
    <v-container class="main-container" dark>
      <v-row>
        <v-col>
          <v-card
            color="pink darken-4"
            style="height:10em; display:flex;flex-direction:column;align-items:center;justify-content:center;transform:translateY(-20%);"
          >
            <h1 style="text-align:center; color:white;">Colegio Cramer</h1>
            <h2 style="color:#a1a1a1;">Inicia sesión</h2>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- <v-img src="https://picsum.photos/510/300?random" aspect-ratio="1.7"></v-img>

                    <v-img src="../assets/leones-cramer.png" aspect-ratio="1.65" height="110%"></v-img>-->
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="email"
            label="Correo institucional"
            :rules="emailRules"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Contraseña"
            hint="por lo menos 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            :disabled="disabled()"
            @click="sendDataLogin()"
            block
          >
            Ingresar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :multi-line="multiLine" :timeout="timeout">
      {{ messageError }}
      <v-btn color="red" text @click="snackbar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
const qs = require("querystring");

import api from "@/api.config.js";

export default {
  name: "LoginForm",
  data: () => ({
    password: "",
    email: "",
    messageError: "",
    multiLine: true,
    snackbar: false,
    timeout: 3000,
    show1: false,
    emailRules: [
      (value) => !!value || "Campo Obligatorio.",
      (value) => (value && value.length >= 3) || "Min 3 characters",
    ],

    rules: {
      required: (value) => !!value || "Campo Obligatorio.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
      emailMatch: () => "The email and password you entered don't match",
    },
  }),
  methods: {
    async sendDataLogin() {
      let data = {
        username: this.email,
        password: this.password,
      };
      const config = {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      await axios
        .post(api.API_PATH + "/api/login/", qs.stringify(data), config)
        .then((result) => {
          //let idlocal = result.data.admin.id;
          //let tokenlocal= result.data.admin.token;
          //console.log(result)

          let token = result.data.token;
          let id = result.data.user.id;
          let rol;
          if (result.data.user.is_student) {
            rol = "student";
            const configure = {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + token,
              },
            };
            axios
              .get(api.API_PATH + "/api/get-id-student/" + id + "/", configure)
              .then((r) => {
                // console.log(r)
                let datalocaltoken = {
                  "token-user": token,
                  rol: rol,
                  id: r.data[0].id,
                };
                localStorage.setItem(
                  "token-user",
                  JSON.stringify(datalocaltoken)
                );
                this.$router.push("/home");
              })
              .catch((e) => {
                console.log(e);
              });
          } else if (result.data.user.is_teacher) {
            rol = "teacher";
            const configure = {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + token,
              },
            };
            axios
              .get(api.API_PATH + "/api/get-id-teacher/" + id + "/", configure)
              .then((r) => {
                //console.log(r)
                let datalocaltoken = {
                  "token-user": token,
                  rol: rol,
                  id: r.data[0].id,
                };
                localStorage.setItem(
                  "token-user",
                  JSON.stringify(datalocaltoken)
                );
                this.$router.push("/home");
              })
              .catch((e) => {
                console.log(e);
              });
          } else if (result.data.user.is_staff) {
            rol = "admin";
            let datalocaltoken = {
              "token-user": token,
              rol: rol,
              id: id,
            };
            localStorage.setItem("token-user", JSON.stringify(datalocaltoken));
            this.$router.push("/home");
          }
        })
        .catch((err) => {
          this.messageError =
            "Algo falló, revisa tu contraseña o correo. " + err;
          this.snackbar = true;
        });
    },
    existToken() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      return !!tok;
    },
    disabled() {
      return !this.email || !this.password;
    },
  },
  created() {
    if (this.existToken()) {
      this.$router.push("/home");
    }
  },
};
</script>

<style scoped>
.login-form-container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
}
</style>
